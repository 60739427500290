
import './loader.css';
export function createLoader() {
    let button = document.createElement('button');
    button.classList.add('btn', 'btn-light');
    let span = document.createElement('span');
    span.classList.add('spinner-grow', 'spinner-grow-sm');
    span.setAttribute('role', 'status');
    span.setAttribute('aria-hidden', 'true');
    let div = document.createElement('div');
    div.classList.add('loaderDiv');
    div.setAttribute('id', 'apiloader');
    let divchild = document.createElement('div');
    divchild.classList.add('wrapperdiv');
    button.appendChild(span);
    button.innerHTML += ' Loading...';
    divchild.appendChild(button);
    div.appendChild(divchild);

    return div.innerHTML;
}
export default async function ApiRequest(url, method, body, header, noloader = false, baseUrl) {
    let div = createLoader(noloader);
    document.getElementById('apiloader').innerHTML = div;
    document.getElementById('apiloader').classList.add('loaderDiv');
    const baseurl = baseUrl ? baseUrl : "https://presale.maximtoken.io/MobileAPI/";
    let obj = {
        method: method
    }
    if (body) {
        obj["body"] = body;
    }
    if (header) {
        obj["headers"] = header
    }
    try {
        let ApiRequest = await fetch(baseurl + url, obj);
        let res = await ApiRequest.json();
        if (div) {
            document.getElementById('apiloader').innerHTML = '';
            document.getElementById('apiloader').classList.remove('loaderDiv');
        }
        return res;
    } catch (e) {
        if (div) {
            document.getElementById('apiloader').innerHTML = '';
            document.getElementById('apiloader').classList.remove('loaderDiv');
        }
        return { status: "error", message: e.message };
    }

}